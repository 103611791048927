import Grid from "@mui/material/Grid";
import ResponsiveAppBar from "./Header";
import Footer from "../widgets/Footer";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ContactUs from "./sections/ContactUs";
import { useParams } from "react-router-dom";
import data from '../data/customer.json';
import Seo from "../widgets/Seo";


const Customer = () => {
	const { customer } = useParams();

	const filteredData = Object.entries(data).filter(([key]) => key === customer);

	const x = filteredData.length > 0 ? filteredData[0][1] : null;

	if (x == null) {
		return <div>
			<h1>404 Page Not Found</h1>
			{/* Additional content for the 404 page */}
		</div> // Return null or any other component if the condition is not met
	}

	return (
		<div >
			<Seo title={x.meta.title} desc={x.meta.desc}></Seo>
			{/* <Helmet>
				<title>{x.meta.title}</title>
				<meta name="og:title" content="X" />
				<meta name="og:description" content="Web Development Agency with expertise in Web Development, Mobile App Development, Android App, IOS App, Javascript, Food E-Commerce Apps, API Integration, Chatbot Development in Multi-Languages" />
				<meta name="og:image" content="/android-chrome.png" />
				<meta name="description" content="Web Development, Mobile App Development, Android App, IOS App, Javascript, Food E-Commerce Apps," />
			</Helmet> */}
			<ResponsiveAppBar />
			<Box style={{ backgroundColor: "#1D233A" }}>
				<Container max-width="lg" sx={{ p: 4, pt: 0, py: 8, backgroundColor: "#1D233A" }} id={"ContactDiv"}>
					<Grid container spacing={2}>
						<Grid item md={6} sm={12} style={{ alignItems: 'center', display: 'flex', marginBottom: '10px' }}>
							<img
								src={x.image}
								alt=""
								width={"100%"}
								style={{ maxWidth: "450px" }}
							/>
						</Grid>
						<Grid item md={6} sm={12}>
							<img src={x.logo} width={"170px"} style={{ maxWidth: "300px", paddingBottom: "5px", cursor: x.companyKey === 'beinfluenced' ? 'pointer' : 'default' }} alt="" onClick={() => {
								if (x.companyKey === 'BeInfluenced') {
									window.open("https://beinfluenced.net", "_blank");
								}
							}} />
							<Typography variant="h6" color="primary" fontWeight={700} lineHeight={1.2} paddingBottom={2}>
								{x.title}
							</Typography>
							<Container>
								<Grid container spacing={0} paddingBottom={3}>
									<Grid item md={2}>
										<hr style={{ width: "50%", borderWidth: "2px", borderColor: "#4A8FAF", marginLeft: "0", }} />
										<Typography variant="body1" color="white" lineHeight={1.2} fontWeight={600}>
											THE <br></br>NEED
										</Typography>
									</Grid>
									<Grid item md={1}></Grid>
									<Grid item md={9} sx={{ justifyContent: "center", alignItems: "end", display: "flex", }}>
										<Typography variant="body2" color="white" fontWeight={300} paddingTop={2}>
											{x.need}
										</Typography>
									</Grid>
								</Grid>
								<Grid container spacing={0} paddingBottom={3}>
									<Grid item md={2}>
										<hr style={{ width: "50%", borderWidth: "2px", borderColor: "#4A8FAF", marginLeft: "0", }} />
										<Typography variant="body1" color="white" lineHeight={1.2} fontWeight={600}>
											THE <br></br>CHALLENGE
										</Typography>
									</Grid>
									<Grid item md={1}></Grid>
									<Grid item md={9} sx={{ justifyContent: "center", alignItems: "end", display: "flex", }}>
										<Typography variant="body2" color="white" fontWeight={300} paddingTop={2}>
											{x.challenge}
										</Typography>
									</Grid>
								</Grid>
								<Grid container spacing={0} paddingBottom={3}>
									<Grid item md={2}> <hr style={{ width: "50%", borderWidth: "2px", borderColor: "#4A8FAF", marginLeft: "0", }} />
										<Typography variant="body1" color="white" lineHeight={1.2} fontWeight={600}>
											THE <br></br>SOLUTION
										</Typography>
									</Grid>
									<Grid item md={1}></Grid>
									<Grid item md={9} sx={{ justifyContent: "center", alignItems: "end", display: "flex", }}>
										<Typography variant="body2" color="white" fontWeight={300} paddingTop={2}>
											{x.solution}
										</Typography>
									</Grid>
								</Grid>
								<Grid container spacing={0} paddingBottom={3}>
									<Grid item md={2}> <hr style={{ width: "50%", borderWidth: "2px", borderColor: "#4A8FAF", marginLeft: "0", }} />
										<Typography variant="body1" color="white" lineHeight={1.2} fontWeight={600}>
											THE <br></br>RESULT
										</Typography>
									</Grid>
									<Grid item md={1}></Grid>
									<Grid item md={9} sx={{ justifyContent: "center", alignItems: "end", display: "flex", }}>
										<Typography variant="body2" color="white" fontWeight={300} paddingTop={2}>
											{x.result}
										</Typography>
									</Grid>
								</Grid>
							</Container>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<ContactUs />
			<Footer />
		</div >
	);
};

export default Customer;
