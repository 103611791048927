import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { InView, } from 'react-intersection-observer';

type props = {
    company: string,
    title: string,
    category: string,
    image: string,
    hoverColor: string,
    textColor: boolean,
    link: string,
    companyKey: string,
    hoverImageLeftPosition: string // true for dark and false for bright on hover
}

const PastWorkRow = ({ company, title, category, image, hoverColor, textColor, link, companyKey, hoverImageLeftPosition }: props) => {
    const isMobile = window.innerWidth <= 768;
    const navigate = useNavigate();
    // const targetRef = useRef(null);


    const Box = styled.div`
        background-color: white;        
        background-image: none;
        // transition: background-color 1s, color 0.5s;
        -webkit-transition: background-image 2s ease-in-out;
        transition: background-image 2s ease-in-out;
        // background-position: 0 0;
        cursor: pointer;
        // animation: animateBackground 5s infinite linear;        
        
        /* Testing */
        // background-image: url('/img/${companyKey}/work-cover.png');
        // .image-container {
        //     opacity: 1;                
        // };

        &:hover {
            // background-color: ${hoverColor};
            color: ${textColor ? 'white' : 'black'};
            background-image: url('/img/${companyKey}/work-cover.png');
            // background-image: url('/img/work-background.png');

            .company-text {
                color: ${textColor ? 'white' : 'black'};
            };

            .image-container {
                opacity: 1;                
            };
        }

        // @keyframes animateBackground {
        //     0% {
        //         background-position: 0 0;
        //     }
        //     100% {
        //         background-position: -200px 0; // Adjust the value based on your needs
        //     }
        // };
    `;

    // For animation - catch scroll intersection
    // const { ref: targetRef, inView, entry } = useInView({
    //     /* Optional options */
    //     threshold: 0,
    // });

    let highlight: boolean = false;

    const toggleAnimation = (inView: boolean, entry: IntersectionObserverEntry) => {
        console.log('inView value', inView);
        console.log('entry value', entry);
        if (isMobile) {
            highlight = inView;
        }
    }

    const x = 3;

    // const targetRef = useRef(ref);

    return (
        <div>
            <InView onChange={(inView, entry) => toggleAnimation(inView, entry)} threshold={0.6}>
                {({ inView, ref, entry }) => (
                    <Box style={{
                        backgroundSize: "cover",
                        backgroundImage: isMobile && highlight ? `url(/img/${companyKey}/work-cover.png)` : '',
                        color: isMobile ? highlight && textColor ? 'white' : 'black' : 'inital',
                        // backgroundColor: highlight ? 'orange' : '',
                    }} className='pastWorkRow' onClick={() => navigate(`/projects/${link}`)} ref={ref}>
                        <Container max-width="lg" sx={{ p: 4, py: 0, }}>
                            <Grid container spacing={0} sx={{ height: isMobile ? '400px' : '310px' }}>
                                <Grid item xs={12} md={6} height={isMobile ? '50%' : '100%'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Typography variant="body2" color={"primary"} fontWeight="400" className="company-text"> {company} </Typography>
                                    <Typography variant="h5" fontWeight={500} sx={{ py: 2 }}>{title}</Typography>
                                    <Typography variant="body1" fontWeight="400" fontSize={14}> {category} </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} height={isMobile ? '50%' : '100%'} sx={{ textAlign: 'center' }}>
                                    <Container className='image-container' sx={{ height: '100%', opacity: isMobile && highlight ? 1 : 0, transition: 'opacity 0.3s', position: 'relative' }}>
                                        <img src={`/img/${companyKey}/work-image.png`} alt="" style={{ height: '100%', maxHeight: '100%', maxWidth: isMobile ? '80vw' : '40vw', left: hoverImageLeftPosition, position: 'absolute', top: 1 > x ? '50px' : undefined, }} className="work-image" />
                                        {/* <h2>{`${company}: ${highlight}`}</h2> */}
                                    </Container>
                                </Grid>
                            </Grid>


                        </Container>
                    </Box>
                )}
            </InView>

        </div >
    );
}
export default PastWorkRow;